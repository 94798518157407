import Acceuil from "./Pages/accueil";
import Form from "./Pages/form";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Wheel from './Pages/WheelOfFortune';
import Win from './Pages/win';

function App() { 
  return (
    <Router>
      <div className="App">
        <Routes>

          <Route path="/form" element={<Form />} />
          <Route path="/" element={<Acceuil />} />
          <Route path="/roue" element={<Wheel />} />
          <Route path="/win" element={<Win />} />
          
        </Routes>
        
      </div>
    </Router>
  );
}



export default App;
