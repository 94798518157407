import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Checkbox, CheckboxGroup } from "@nextui-org/react";
import confetti from "canvas-confetti";
import Question from "./Question";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faGroupArrowsRotate, faCirclePlay, } from '@fortawesome/free-solid-svg-icons';
import { getAuth,signOut } from "firebase/auth";
import { Link } from "react-router-dom";


const WheelOfFortune = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [chancesLeft, setChancesLeft] = useState(3);
  const auth = getAuth();
  

  const wheelControls = useAnimation();

  const prizes = [
    { label: "🎁" },
    { label: "🍎" },
    { label: "🎁" },
    { label: "💣" },
    { label: "🎁" },
    { label: "💎" },
    { label: "⭐" },
    { label: "☘️" },

  ];
  
  useEffect(() => {
    if (
      chancesLeft === 0 && !isPopupOpen) {
      // Ouvrir le deuxième modal lorsque les conditions sont remplies
      setIsResetOpen(true);
    }
  }, [chancesLeft, isResetOpen]);



  const spinWheel = async () => {
    if (isSpinning || chancesLeft === 0) 
    
    return ;

    setIsSpinning(true);

    // Décrémenter le nombre de chances restantes
    setChancesLeft((prevChances) => prevChances - 1);

          
   
    // Réinitialiser les contrôles d'animation
    wheelControls.stop();
    wheelControls.set({ rotate: 0 });

    const randomIndex = Math.floor(Math.random() * prizes.length);

    try {
      await wheelControls.start({
        rotate: 360 * 8 + (360 / prizes.length) * randomIndex + 22,
        transition: { duration: 5, ease: [0.2, 0.8, 0.2, 1] },
      });

      const resultIndex = (prizes.length - randomIndex) % prizes.length;
      const selectedPrize = prizes[resultIndex];

      setSelectedPrize(selectedPrize);

 


      if (selectedPrize.label === "🎁") {
        // Afficher les confettis
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });

        // Afficher le popup (remplacez ce code par votre propre logique de popup)
        setIsPopupOpen(true);
      }



    } finally {
      setIsSpinning(false);
      

    }
  };

  const handleQuit = async () => {
    try {
      // Déconnectez l'utilisateur anonyme
      await signOut(auth);
    } catch (error) {
      console.error("Erreur lors de la déconnexion : ", error);
    }
  };



  return (
    <div style={{ textAlign: "center", fontFamily: "sans-serif" }}>

      <CheckboxGroup
        orientation="horizontal"
        value={chancesLeft === 3 ? [] : chancesLeft === 2 ? ["1"] : chancesLeft === 1 ? ["1", "2"] : chancesLeft === 0 ? ["1", "2", "3"] : []}

        size="sm"
        style={{ alignItems: 'center' }}

      >
        <Checkbox value="1" color="primary" />
        <Checkbox value="2" color="warning" />
        <Checkbox value="3" color="danger" />
      </CheckboxGroup>




      <div
        style={{
          position: "absolute",
          top: "50px",
          left: "calc(50% - 10px)",
          width: "20px",
          height: "20px",
          backgroundColor: "#ff3300",
          transform: "rotate(-45deg)",
          transformOrigin: "center",
          zIndex: 2,
        }}
      />
      <motion.div
        className="wheel"
        initial={{ rotate: 22 }}
        animate={wheelControls}
        style={{
          width: "400px",
          height: "400px",
          position: "relative",
          boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.3)",
          overflow: "hidden",
          borderRadius: "50%",
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/roue%2Fwheel.png?alt=media&token=6bc8d20c-23e1-4310-9ead-00a19e471b5d"
          alt="wheel"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) scale(1.08) ",
            width: "100%",
            height: "100%",

            objectFit: "contain",
          }}
        />
        {prizes.map((prize, index) => (
          <motion.img
            key={index}

            alt={prize.label}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%) rotate(${(360 / prizes.length) * index - 25}deg)`,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              visibility: "hidden",

            }}
          />
        ))}

      </motion.div>
      <motion.button
        onClick={spinWheel}
        disabled={isSpinning}
        style={{
          //backgroundColor: "#ff3300",
          color: "#fff",
          border: "none",
          padding: "20px",
          fontSize: "15px",
          borderRadius: "50%",
          cursor: "pointer",
          boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.3)",
          position: "absolute",
          top: "46%",
          left: "50.3%",
          transform: "translate(-50%, -50%)",
          zIndex: 3,
        }}
      >
        <FontAwesomeIcon icon={isSpinning ? faGroupArrowsRotate : faCirclePlay}
          beat
          spin={isSpinning}
          spinReverse={isSpinning} className="mr-2" size="2xl"
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        />

      </motion.button>
      <p style={{ marginTop: "20px", fontSize: "24px",  fontWeight: "bold" }}>
        Résultat:{" "}
        <span style={{   fontWeight: "bold" }}>
          {selectedPrize ? selectedPrize.label : "Appuie sur le bouton"}
        </span>
      </p>
      <Modal
        backdrop="opaque"
        isOpen={isPopupOpen}
        onOpenChange={() => setIsPopupOpen(false)} // Fermer le popup en changeant l'état
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Félicitation!</ModalHeader>
              <ModalBody>
                <p>Vous êtes éligible pour remporter le : {selectedPrize ? selectedPrize.label : ""}</p>
                <p> Vous devez trouver les réponses aux questions suivantes</p>
              </ModalBody>
              <ModalFooter>

                <Question />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        backdrop="opaque"
        isOpen={isResetOpen}
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
                <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Réessayer?</ModalHeader>
              <ModalBody>
                <p>Tentez votre chance de nouveau </p>
                
              </ModalBody>
              <ModalFooter>


          <Link to ="/roue">
          <Button
            className="bg-[#F9FAFA] shadow-lg shadow-green-500/20"
            onPress={() => {
              
              
            }}
          >
            Réessayer
          </Button>
          </Link>
          <Link to="/win" >
          <Button color="foreground" variant="light" onPress={handleQuit}>
            Quitter
          </Button>
          </Link>
          
          </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

    </div>
  );
};

export default WheelOfFortune;