import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyByUon8pBWUG8WDJbmBuAsFY-7kuOApmA0",
  authDomain: "wheeloffortune--boa.firebaseapp.com",
  databaseURL: "https://wheeloffortune--boa-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wheeloffortune--boa",
  storageBucket: "wheeloffortune--boa.appspot.com",
  messagingSenderId: "349164580641",
  appId: "1:349164580641:web:4eafcef8260ed939ac5860",
  measurementId: "G-Q45J9KV10J"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;