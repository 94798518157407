import React, { useState } from "react";
import { Card, CardHeader, CardBody, Modal, ModalContent, ModalHeader, ModalBody, Button, Image, ModalFooter } from "@nextui-org/react";
import WheelOfFortune from "../Components/roue";

import { FaGift } from "react-icons/fa";


export default function Wheel() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{
      justifyContent: "center", alignItems: "center", display: "flex"

    }}>
      <Card className="py-4" style={{
        width: '700px',

        backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/floue.png?alt=media&token=765631dc-f6a0-4284-a9f4-3b02c067d8d1")', // Définissez l'image de fond ici
        backgroundSize: 'cover', // Ajustez la taille de l'image de fond selon vos besoins
        backgroundPosition: 'center', // Centrez l'image de fond horizontalement et verticalement
        
        
        

      }}>
        <style>
          {`
        .centered-content {
            display: flex;
            justify-content: center; /* Centre horizontalement */
            align-items: center; /* Centre verticalement */
            flex-direction: column; /* Pour centrer le contenu verticalement */
            height: 100%; /* Remplir la hauteur de la carte */
          }
          
          .custom-card {
            width: 450px;
            /* Ajoutez d'autres styles personnalisés ici */
          }
        `}

        </style>
        <div 
        style={{
          backdropFilter: "blur(50px)",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          }}
        >
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start centered-content"
        
        >
          <div className="flex items-center mb-1">
          <Image
            alt="nextui logo"
            height={40}
            radius="sm"
            src="https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/Logo1.jpg?alt=media&token=364c79aa-abdd-4d3b-a899-192141711577"
            width={40}
          />

            
          </div>


        </CardHeader>
        <CardBody className="overflow-visible py-2 centered-content">

          <WheelOfFortune />

        </CardBody>
        </div>
      </Card>
      {/* Modal avec une image */}
      <Modal backdrop="opaque"
        isOpen={isModalOpen}
        onClose={closeModal}
        classNames={{
          //body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          //base: "border-[#292f46] bg-[#19172c] dark:bg-[#19172c] text-[#a8b0d3]",
          //header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-black/5 active:bg-black/10",
        }}
      >
        <ModalContent>
          <ModalHeader>
            Comment Jouer
          </ModalHeader>

          <ModalBody>
            {/* Ajoutez ici votre image */}
            <Image src="https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/tuto%2Ftuto.png?alt=media&token=55916a3d-cf07-4d6d-9ef0-47bdcab9d23b" alt="Tuto" width={400} height={300} />
          </ModalBody>


          <p
            //ajoute un style pour centrer
            className="text-center text-sm text-gray-500 dark:text-gray-400 py-2"
          >
            Cliquez sur le bouton au centre pour lancer la roue
          </p>
          <Button className="bg-[#048337] shadow-lg shadow-indigo-500/20" onClick={closeModal} style={{ backgroundColor: "#048337", color: "white" }} >
            Fermer
          </Button>

        </ModalContent>
      </Modal>
    </div>
  );
}